require('./bootstrap');

require('./video');

require('../../node_modules/photoviewer/dist/photoviewer')

dernieres_conversations = require('./dernieres_conversations');

menu_utilisateur = require('./menu_utilisateur');

derniers_connectes = require('./derniers_connectes');

regie = require('./regie');

warning = require('./content_warning');

$(document).ready
(
    function()
    {
        $('[data-toggle="tooltip"]').tooltip();
        if(window.content_warning == 1)
            warning.content_warning();
        if($(".menu-utilisateur").length)
            setInterval(menu_utilisateur.updateMenuUtilisateur, 30000);
        if($("#conversations-index").length)
            setInterval(dernieres_conversations.updateDernieresConversations, 20000);
        if($("#messages-index").length)
            setInterval(dernieres_conversations.updateDerniersMessages, 5000);
        regie.updateRegie(1, 'regie_laterale');
        regie.updateRegie(2, 'regie_inferieure');
        setInterval(derniers_connectes.updateDerniersConnectes, 60000);
    }
);

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})
