exports.updateDerniersConnectes = function()
{
    $.ajax
    (
        {
            type: "GET",
            url: "/profile/ajax/derniers_connectes",
            cache: true,
            success: function (response)
            {
                $("#derniers_connectes").html(response);
            }
        }
    );
}
