require('../../node_modules/video.js/core.js');

import videojs from 'video.js';

videojs.addLanguage
('fr',
    {
        "The media could not be loaded, either because the server or network failed or because the format is not supported."
            : "Vidéo en cours d'encodage."
    }
);
