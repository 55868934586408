exports.updateRegie = function (emplacement_id, emplacement)
{
    $.ajax
    (
        {
            type: "GET",
            url: "/regie/" + emplacement_id,
            cache: true,
            success: function (response)
            {
                $("#" + emplacement).html(response);
            }
        }
    );
}

