exports.updateDernieresConversations = function ()
{
    $.ajax
    (
    {
            type: "GET",
            url: "/conversation/ajax/index/" + window.profile_id + "?page=" + window.page_number,
            cache: true,
            success: function (response)
            {
                $("#conversations-index").html(response);
            }
        }
    );
}

exports.updateDerniersMessages = function ()
{
    $.ajax
    (
        {
            type: "GET",
            url: "/message/ajax/index/" + window.interlocuteur_id,
            cache: true,
            success: function (response)
            {
                $("#messages-index").html(response);
                deconnexion = "<div class=\"alert alert-info\">Vous avez été déconnecté</div>\n";
                if (response == deconnexion)
                {
                    $('#chat-btn').prop('disabled', true);
                    $('#chat-txt').prop('disabled', true);
                }
            }
        }
    );
}

