exports.updateMenuUtilisateur = function ()
{
    $.ajax
    (
        {
            type: "GET",
            url: "/profile/ajax/menu",
            cache: true,
            success: function (response) {
                $(".menu-utilisateur").html(response);
            }
        }
    );
}

